<!-- CONTAINER -->
<div class="terms-of-use__container flex flex-col items-start">

  <!-- HEADER -->
  <h1 class="terms-of-use__header mb-8">
    <strong>
      {{ 'Terms Of Use.Terms Of Use' | translate }}
    </strong>
  </h1>

  <!-- CONTENT -->
  <div class="terms-of-use__content text-left max-w-full md:max-w-2/3">

    <div class="html-content" [innerHTML]="'Terms Of Use.Content' | translate | safe: 'html'">
    </div>

  </div>

  <!-- BUTTON -->
  <div class="terms-of-use__button text-center mb-8 flex flex-row">
    <kb-buttons-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Terms Of Use.Scroll Up' | translate">
    </kb-buttons-default>
  </div>

</div>