import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeTilesNumberCardComponent } from './home-tiles-number-card.component';
import { HomeTileModule } from '@app/home/shared/tile/home-tile.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,

    HomeTileModule,
    TranslateModule
  ],
  declarations: [HomeTilesNumberCardComponent],
  exports: [HomeTilesNumberCardComponent]
})
export class HomeTilesNumberCardModule { }
