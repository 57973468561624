import { Component, Input } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
  selector: 'kb-home-tile-header',
  templateUrl: './home-tile-header.component.html'
})
export class HomeTileHeaderComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  /**
   * The appearance of the title. Narrow will display the heading in a different font
   * size and color than standard
   */
  @Input() appearance: 'narrow' | 'standard' = 'standard'
  
}
