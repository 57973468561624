import { NgModule } from '@angular/core';
import { OrdersListModule } from '@app/orders/list/orders-list.module';
import { SharedModule } from '@app/shared';
import { PageModule } from '@app/shared/components/page/page.module';
import { DashboardService } from '@app/shared/services/dashboard.service';

import { HomeComponent } from './home.component';
import { HomeTileModule } from './shared/tile/home-tile.module';
import { HomeTilesDeliveryMethodsComponent } from './tiles/delivery-methods/home-tiles-delivery-methods.component';
import { HomeTilesNumberCardModule } from './tiles/number-card/home-tiles-number-card.module';
import { HomeTilesPerDayComponent } from './tiles/per-day/home-tiles-per-day.component';
import { HomeTilesCityComponent } from './tiles/city/home-tiles-city.component';
import { ButtonsCreateModule } from '../shared/components/buttons/create/buttons-create.module';

@NgModule({
  imports: [
    SharedModule,

    PageModule,
    ButtonsCreateModule,
    HomeTileModule,
    HomeTilesNumberCardModule,
    HomeTilesDeliveryMethodsComponent,
    HomeTilesCityComponent,
    HomeTilesPerDayComponent,

    OrdersListModule
  ],
  declarations: [HomeComponent],
  providers: [DashboardService],
  exports: [HomeComponent]
})
export class HomeModule { }
