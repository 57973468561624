import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BaseService } from '../../base/services';

@Injectable()
export class ForbiddenService extends BaseService {
    private _isForbidden: boolean;

    private _isForbidden$: Subject<boolean> = new Subject<boolean>();

    // tslint:disable-next-line:member-ordering
    isForbidden$: Observable<boolean> = this._isForbidden$.asObservable();

    forbid(): void {
        this._isForbidden$.next(true);
    }

    allow(): void {
        this._isForbidden$.next(false);
    }
}