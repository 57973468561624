import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '@app/shared/services/local/side-bar.service';

import { BaseSideBarComponent } from '../shared/base-side-bar-component';

@Component({
  selector: 'kb-side-bar-navigation',
  templateUrl: './side-bar-navigation.component.html',
  styleUrls: ['./side-bar-navigation.component.scss'],
})
export class SideBarNavigationComponent extends BaseSideBarComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isCreatorOrCuratorRoute: boolean;

  isCreatorRoute: boolean;

  isLoadingWaveform: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef
  ) {
    super(sideBarService);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  scrollToFragment(fragment: string): void {
    const element = this.document.getElementById(fragment);
    const content = this.document.getElementById('content');
    const headerOffset = 200;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + content.scrollTop - headerOffset;

    content.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  getNameOrNull(title: string): string {
    return this.isExpanded ? title : '';
  }
}
