import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ButtonsDefaultModule } from '@app/shared/components/buttons/default/buttons-default.module';
import { ButtonsSubmitModule } from '@app/shared/components/buttons/submit/buttons-submit.module';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { SharedModule } from '@app/shared/shared.module';

import { FormDeactivateableDialogComponent } from './form-deactivateable-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    DialogModule,
    MatButtonModule,
    ButtonsSubmitModule,
    ButtonsDefaultModule
  ],
  declarations: [FormDeactivateableDialogComponent],
  exports: [FormDeactivateableDialogComponent]
})
export class FormDeactivateableDialogModule { }
