<!-- LOGO -->

<!-- EXPANDED -->
<ng-container *ngIf="isExpanded === true; else collapsed;">
    <div class="h-12 relative">
        <img src="../../../../../../assets/logo/hor_4c_neg.png" class="h-8 w-auto" alt="Sendable Logo">
    </div>
</ng-container>

<!-- COLLAPSED -->
<ng-template #collapsed>
    <img src="../../../../../../assets/logo/mark_4c.png" class="h-8" alt="Sendable Logo">
</ng-template>
