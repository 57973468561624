<!-- Router link active will not work with dynamic content -->
<a (click)="redirectToLink()" [matTooltip]="tooltip" matTooltipPosition="above"
  class="navigation-link group flex flex-col gap-1 md:gap-0 md:flex-row items-center" [ngClass]="[
    navigationLinkClass,
    justifyClass,
    !IsNullOrUndefinedOrEmpty(displayName) ? 'pl-6': '',
    !IsNullOrUndefinedOrEmpty(link) && IsNullOrUndefinedOrEmpty(fragment) && router.url.includes(link) ? !IsNullOrUndefinedOrEmpty(displayName) ? 'active' : 'active active--borderless' : '',
    iconClass?.includes('logo') ? 'navigation-link--logo' : '']">
  <div class="flex-initial flex" *ngIf="!IsNullOrUndefinedOrEmpty(icon)">
    <mat-icon [svgIcon]="icon" class="navigation-link--image" [ngClass]="[iconClass, 'large']"></mat-icon>
  </div>
  <div class="navigation-link__text hidden md:block" [ngClass]="{'flex-auto ml-2 uppercase': !IsNullOrUndefinedOrEmpty(displayName)}">
    {{ displayName }}
  </div>
</a>