import { Component } from '@angular/core';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { BaseSideBarComponent } from '../shared/base-side-bar-component';

@Component({
  selector: 'kb-side-bar-header',
  templateUrl: './side-bar-header.component.html',
  styleUrls: ['./side-bar-header.component.scss']
})
export class SideBarHeaderComponent extends BaseSideBarComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService,
  ) {
    super(sideBarService);
  }
}
