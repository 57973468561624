import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DataProtectionComponent } from './core/data-protection/data-protection.component';
import { ImprintComponent } from './core/imprint/imprint.component';
import { Route } from './core/Routes';
import { TermsAndConditionsComponent } from './core/terms-and-conditions/terms-and-conditions.component';
import { HomeComponent } from './home/home.component';
import { AppRoute } from './shared/app.route.enum';
import { Role } from './shared/models/classes/Role';

const routes: Routes = [
  // The base layout and authentication takes care of authentication
  // for every request. Use the authentication guard on lower routing levels
  // to enable permission based authorization (see below)
  {
    path: '',
    redirectTo: `/${AppRoute.Home}`,
    pathMatch: 'full'
  },
  Route.withBaseLayoutAndAuthentication([
    {
      path: AppRoute.Forbidden,
      loadChildren: () => import('./core/forbidden/forbidden.module').then(m => m.ForbiddenModule)
    },
    {
      path: AppRoute.Settings,
      loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
    {
      path: AppRoute.Home,
      component: HomeComponent,
      data: {
        title: 'Title.Home | Sendable'
      }
    },
    {
      path: AppRoute.Orders,
      loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
    },
    /*{
      path: AppRoute.Partners,
      loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule)
    },*/
    {
      path: AppRoute.Support,
      loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
    },
    {
      path: AppRoute.FAQs,
      loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule)
    },
    {
      path: AppRoute.Imprint,
      component: ImprintComponent,
      data: {
        title: 'Title.Legal Notice | Sendable'
      }
    },
    {
      path: AppRoute.PrivacyPolicy,
      component: DataProtectionComponent,
      data: {
        title: 'Title.Data protection | Sendable'
      }
    },
    {
      path: AppRoute.TermsOfUse,
      component: TermsAndConditionsComponent,
      data: {
        title: 'Title.Terms Of Use | Sendable'
      }
    },
    {
      path: AppRoute.Admin,
      data: {
        roles: [Role.Administrator, Role.CompanyAdministrator]
      },
      loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
  ]),
  { path: '**', redirectTo: `/${AppRoute.Home}` }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
