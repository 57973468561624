import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
  selector: 'kb-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss']
})
export class DataProtectionComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    super();

    this.scrollUp();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  scrollUp(): void {
    const content = this.document.getElementById('content');
    content.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

}
