import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationLinkModule } from '../../navigation/link/navigation-link.module';
import { SideBarHeaderComponent } from './side-bar-header.component';

@NgModule({
  imports: [
    CommonModule,
    NavigationLinkModule,
    NgOptimizedImage
  ],
  declarations: [SideBarHeaderComponent],
  exports: [SideBarHeaderComponent]
})
export class SideBarHeaderModule { }
