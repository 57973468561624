import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { DashboardOverviewMetricsDto, DashboardService } from '@app/shared/services/dashboard.service';
import moment from 'moment';
import { AppRoute } from '../shared/app.route.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'kb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  now = moment().toDate();

  oneMonthAgo = moment().subtract(1, 'month').toDate();

  oneYearAgo = moment().subtract(1, 'year').toDate();


  result: DashboardOverviewMetricsDto = null;

  organisation: { name: string };

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService
  ) {
    super();

    this.organisation = this.authenticationService.credentials.organisation;
  }

  ngOnInit(): void {
    this.getOverviewMetrics();
  }

  navigateToNewOrder(): void {
    this.router.navigateByUrl(`${AppRoute.Orders}/create`);
  }

  private getOverviewMetrics(): void {
    const now = moment();

    super.addSubscription(
      this.dashboardService
        .getDashboardOverview(`month=${now.get('month') + 1}&year=${now.get('year')}`)
        .pipe(this.finalizeLoading())
        .subscribe({
          next: (result: DashboardOverviewMetricsDto) => {
            this.result = result;
          }
        })
    )
  }
}
