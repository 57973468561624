import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { __ } from '@app/shared/functions/object.functions';
import { RedirectService } from '@app/shared/services/redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { RouteService } from '../route.service';
import { AuthenticationService } from './authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, firstValueFrom, from, of } from 'rxjs';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private oAuthService: OAuthService,
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.oAuthService.hasValidAccessToken()) {

      const data = RouteService.MergeData(route);

      if (!__.IsNullOrUndefined(data['roles'])) {
        const allowed = this.authenticationService.hasAnyRole(data['roles']);
        if (allowed) {
          return of(true);
        } else {
          return of(this.checkForbidden(route));
        }
      }

      return of(true);
    }

    this.redirectService.setRedirectUri(window.location.pathname);

    return of(false);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private checkForbidden(route: ActivatedRouteSnapshot): boolean {
    // TODO: POTENTIALLY BREAKING
    if (route['_routerState'].url === '/' || route['_routerState'].url.includes(AppRoute.Home)) {
      return true;
    }
    this.toastr.error(this.translateService.instant('General.You are not allowed to view this page. Please ask your administrator for permissions to view this page'));
    this.router.navigate([`/${AppRoute.Home}`], { replaceUrl: true });

    return false;
  }
}
