<!-- CONTAINER -->
<div class="data-protection__container flex flex-col items-start">

  <!-- HEADER -->
  <h1 class="data-protection__header mb-8">
    <strong>
      {{ 'Data Protection.Data Protection' | translate }}
    </strong>
  </h1>

  <!-- CONTENT -->
  <div class="data-protection__content text-left max-w-full md:max-w-2/3">

    <div class="html-content" [innerHTML]="'Data Protection.Content' | translate | safe: 'html'">
    </div>

  </div>

  <!-- BUTTON -->
  <div class="data-protection__button text-center mb-8 flex flex-row">
    <kb-buttons-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Data Protection.Scroll Up' | translate">
    </kb-buttons-default>
  </div>

</div>