<div class="main-container flex flex-row min-h-screen" style="max-width: 100vw">

  <!-- SIDE BAR -->
  <kb-side-bar class="side-bar"></kb-side-bar>

  <div id="header" class="flex-auto w-auto">

    <!-- CONTENT -->
    <div id="content" [ngClass]="{ 'content flex flex-col bg-primary-gray pb-16 md:pb-0': true, 'content--sidebar': sideBarExpanded }">
      <ng-container *ngIf="isForbidden === true">
        <h3 class="pt-10 mt-24">{{'General.You do not have access to this item' | translate }}</h3>
        <p>{{'General.In most cases, the item you wanted to access requires permissions that you do not have.'
          | translate }}<br>
          {{
          'General.If this is a mistake, please contact your company administrator to ask for the required permissions.'
          | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="notFound === true">
        <h3>{{'General.The item you were looking for could not be found' | translate }}</h3>
        <p>{{'General.In most cases, the item you wanted to access does not exist or has been deleted.' | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="isForbidden === false && notFound === false">
        <div class="flex-grow">
          <router-outlet></router-outlet>
        </div>
      </ng-container>
    </div>
  </div>

</div>