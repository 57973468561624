<kb-page>

    <!-- HEADER -->
    <kb-page-header>

        <!-- TITLE -->
        <kb-page-title testId="home.header.title">
            {{ 'Home.Heading' | translate:{ name: organisation.name} }}
        </kb-page-title>

    </kb-page-header>

    <!-- CONTENT -->
    <kb-page-content>

        <kb-buttons-create class="flex-initial" (click)="navigateToNewOrder()">
            {{ 'Orders.List.Create a new order' | translate }}
        </kb-buttons-create>

        <!-- ANALYTICS -->

        <div class="grid grid-cols-12 grid-rows-1 gap-6 mt-4">

            <!-- OPEN ORDERS -->
            <kb-home-tiles-number-card class="col-span-12 sm:col-span-6 2xl:col-span-3"
                [title]="'Home.Tiles.Open orders' | translate" [isLoading]="isLoading" [value]="result?.openOrdersCount"
                [icon]="'svg'"></kb-home-tiles-number-card>

            <!-- ORDERS THIS MONTH -->
            <kb-home-tiles-number-card class="col-span-12 sm:col-span-6 2xl:col-span-3"
                [title]="'Home.Tiles.Orders in' | translate: { period: (now | date:'MMMM y') }" [isLoading]="isLoading"
                [value]="result?.ordersInCurrentMonthCount" [referencePeriod]="oneMonthAgo | date:'MMMM y'"
                [secondaryValue]="result?.ordersInPreviousMonthPercentage" [icon]="'svg'"></kb-home-tiles-number-card>

            <!-- DELIVERY PROGRESS -->
            <kb-home-tiles-number-card class="col-span-12 sm:col-span-6 2xl:col-span-3" [isLoading]="isLoading"
                [title]="'Home.Tiles.In progress' | translate" [value]="result?.inProgressCount"
                [icon]="'svg'"></kb-home-tiles-number-card>

            <!-- ORDERS TOTAL THIS YEAR -->
            <kb-home-tiles-number-card class="col-span-12 sm:col-span-6 2xl:col-span-3"
                [title]="'Home.Tiles.Total orders in' | translate: { year: now | date:'y' }" [isLoading]="isLoading"
                [value]="result?.ordersInCurrentYearCount" [referencePeriod]="oneYearAgo | date:'y'"
                [secondaryValue]="result?.ordersInPreviousYearPercentage" [icon]="'svg'"></kb-home-tiles-number-card>

        </div>

        <div class="grid grid-cols-12 mt-6 grid-rows-4 gap-6">

            <!-- RECENT ORDERS -->
            <kb-home-tile class="col-span-12 2xl:col-span-9 row-span-2">
                <kb-home-tile-header>{{ 'Home.Tiles.Recent orders' | translate }}</kb-home-tile-header>
                <kb-home-tile-content class="flex">
                    <kb-orders-list class="-m-6" [showHeader]="false" [showTitle]="false"
                        [pageSize]="10"></kb-orders-list>
                </kb-home-tile-content>
            </kb-home-tile>

            <!-- ORDERS BY CITY -->
            <kb-home-tile class="col-span-12 sm:col-span-6 2xl:col-span-3 row-span-2">
                <kb-home-tile-header>{{ 'Home.Tiles.Orders by city' | translate }}</kb-home-tile-header>
                <kb-home-tile-content class="flex">
                    <kb-home-tiles-city></kb-home-tiles-city>
                </kb-home-tile-content>
            </kb-home-tile>

            <!-- DELIVERY METHODS -->
            <kb-home-tile class="col-span-12 sm:col-span-6 2xl:col-span-3 row-span-2">
                <kb-home-tile-header>{{ 'Home.Tiles.Delivery methods' | translate }}</kb-home-tile-header>
                <kb-home-tile-content class="flex">
                    <kb-home-tiles-delivery-methods></kb-home-tiles-delivery-methods>
                </kb-home-tile-content>
            </kb-home-tile>

            <!-- INSIGHTS -->
            <kb-home-tile class="col-span-12 2xl:col-span-9 row-span-2">
                <kb-home-tile-header>{{ 'Home.Tiles.Insights.Title' | translate }}</kb-home-tile-header>
                <kb-home-tile-content class="flex">
                    <kb-home-tiles-per-day></kb-home-tiles-per-day>
                </kb-home-tile-content>
            </kb-home-tile>

        </div>

    </kb-page-content>

</kb-page>