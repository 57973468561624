import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeTileContentComponent } from './home-tile-content.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HomeTileContentComponent],
  exports: [HomeTileContentComponent]
})
export class HomeTileContentModule { }
