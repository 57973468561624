import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { DashboardService } from '@app/shared/services/dashboard.service';
import { Color, LegendPosition, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'kb-home-tiles-city',
  standalone: true,
  imports: [
    CommonModule,

    NgxChartsModule
  ],
  providers: [
    DashboardService
  ],
  templateUrl: './home-tiles-city.component.html'
})
export class HomeTilesCityComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  series: any[] = [];

  scheme: Color = {
    name: 'primary',
    selectable: true,
    group: ScaleType.Linear,
    domain: DashboardService.DefaultDomain
  };

  LegendPosition = LegendPosition;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private dashboardService: DashboardService
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.isLoading = true;

    super.addSubscription(
      this.dashboardService
        .getOrdersPerCity()
        .pipe(this.finalizeLoading())
        .subscribe({
          next: (result: any) => {
            this.series = result.series;

            if (this.series.length === 0) {
              this.series = [
                {
                  value: 0,
                  name: 'Hamburg',
                  label: '0'
                },
                {
                  value: 0,
                  name: 'München',
                  label: '0'
                }
              ]
            }
          }
        })
    );
  }

}
