import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SafeModule } from '@app/shared/pipes/safe.module';
import { MatIconModule } from '@angular/material/icon';
import { ButtonsDefaultModule } from '@app/shared/components/buttons/default/buttons-default.module';

@NgModule({
  imports: [
    CommonModule,

    MatCardModule,
    RouterModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    ButtonsDefaultModule,
    SafeModule,
  ],
  declarations: [TermsAndConditionsComponent]
})
export class TermsAndConditionsModule { }
