import { Location } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { RouteService } from '@app/core/route.service';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { Logger } from '../logger/logger.service';

const log = new Logger('BreadcrumbComponent');

export interface WithBreadcrumbs {
  setBreadCrumb(): void;
}

export class BreadcrumbSegment {
  title: string;
  routerLink: string;
  path: string;
  viewName: string;
}

export const APP_ROUTE = new InjectionToken<string>('AppRoute');
export const APP_TITLE = new InjectionToken<string>('AppTitle');

/**
 * The breadcrumb service creates breadcrumbs by subscribing to the urlSegments of the active route and
 * to breadcrumbSegments of the breadcrumb service. The routerLinks of the breadcrumbs will be build and
 * displayed with a translateable title.
 *
 * WARNING: - DOES NOT WORK ON SAME ROUTE NAVIGATION (COMPONENT WILL NOT BE INITIALIZED AGAIN WHEN E.G.
 *            ONLY THE ID PARAMETER CHANGES IN A ROUTE).
 *          - MAY NOT WORK WITH MORE A GREATER HIEARCHY THAN E.G. limited/editions/3/edit due to
 *            this.breadcrumbs.slice
 */
@Injectable()
export class BreadcrumbsService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private breadcrumbSegments: BreadcrumbSegment[] = [];

  private currentUrl: string = '';

  private _breadcrumbSegments$: BehaviorSubject<BreadcrumbSegment[]> = new BehaviorSubject<BreadcrumbSegment[]>(null);

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  /**
   * The observable where changes in the breadcrumb segments array will be pushed to
   *
   */
  breadcrumbSegments$: Observable<BreadcrumbSegment[]> = this._breadcrumbSegments$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(APP_ROUTE) private AppRoute: any,
    @Inject(APP_TITLE) private appTitleKey: string
  ) {
    super();

    if (__.IsNullOrUndefined(this.AppRoute)) {
      throw new Error(
        'The injection token APP_ROUTE has not been provided. The breadcrumbs service cannot be used without it.'
      );
    }

    this.init();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Adds a new breadcrumb to the list of breadcrumbs based on the provided title, optional identifier and optional view name.
   * If the identifier is left empty, no routing link will be produced for navigation calculation. The view name can be used
   * to further specify the current breadcrumb with e.g sub navigation names, which will be displayed without a router link
   * behind the provided title.
   *
   * @param title The title that will be displayed in the breadcrumb list
   * @param  identifier The optional identifier that will be used for routing purposes
   * @param viewName The optional view name to further specify sub navigation
   */
  addBreadcrumb(title: string, identifier?: string, viewName?: string): void {
    if (__.IsNullOrUndefinedOrEmpty(title)) {
      log.error('The title of a breadcrumb has not been set');
      return;
    }

    const _identifier = identifier || null;
    const _viewName = viewName || null;

    if (this.breadcrumbSegments.findIndex(q => q.title === title && q.path === identifier) > -1) {
      log.error('The same breadcrumb cannot be added twice');
      return;
    }

    const routerLink = __.IsNullOrUndefinedOrEmpty(identifier)
      ? null
      : `${this.buildRouterLinkFromPreviousBreadcrumbPaths()}${identifier}`;

    this.breadcrumbSegments.push(
      Object.assign(new BreadcrumbSegment(), {
        routerLink,
        title,
        path: _identifier,
        viewName: _viewName
      } as BreadcrumbSegment)
    );
    this._breadcrumbSegments$.next(this.breadcrumbSegments);
  }

  removeLast() {
    this.breadcrumbSegments.pop();
    this._breadcrumbSegments$.next(this.breadcrumbSegments);
  }

  /**
   * Gets the current breadcrumbs
   *
   */
  getCurrentBreadcrumbs(): BreadcrumbSegment[] {
    return this._breadcrumbSegments$.value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private init(): void {
    this.breadcrumbSegments.push(
      Object.assign(new BreadcrumbSegment(), {
        routerLink: '/',
        title: this.appTitleKey,
        path: '/'
      } as BreadcrumbSegment)
    );

    this._breadcrumbSegments$.next(this.breadcrumbSegments);

    super.addSubscription(
      this.router.events
        .pipe(
          filter(event => {
            return event instanceof NavigationEnd;
          }),
          mergeMap(route => {
            return of(RouteService.MergeUrlSegments(this.route.snapshot))
          })
        )
        .subscribe(this.calculateUrlSegments.bind(this))
    );
  }

  private calculateUrlSegments(urlSegments: UrlSegment[]): void {
    if (this.currentUrl === this.location.path()) {
      return;
    }

    this.currentUrl = this.location.path();

    // Depending on how many url segments there are now, only remove n - 1

    this.breadcrumbSegments = this.breadcrumbSegments.slice(0, 1); // urlSegments.length
    // The first url segment should be the resource (based on REST)
    if (!__.IsNullOrUndefined(urlSegments[0])) {
      const validRouteKeys: string[] = Object.keys(this.AppRoute);
      const validRoutes = validRouteKeys.map((k: any) => this.AppRoute[k as any]).map((v: any) => v);

      if (validRoutes.find(q => q === urlSegments[0].path)) {
        this.breadcrumbSegments.push(
          Object.assign(new BreadcrumbSegment(), {
            routerLink: `${this.buildRouterLinkFromPreviousBreadcrumbPaths()}${urlSegments[0].path}`,
            title: this.getTitleOfUrlPath(urlSegments[0].path),
            path: urlSegments[0].path
          }) as BreadcrumbSegment
        );
      } else {
        log.warn(`The route ${urlSegments[0]} is not a valid route of the AppRoute enum`);
      }
    }
    // The rest of the url segments have to be handled in the components using the breadcrumb.service

    this._breadcrumbSegments$.next(this.breadcrumbSegments);
  }

  private getTitleOfUrlPath(path: string): string {
    throw new Error('Not implemented');
  }

  private buildRouterLinkFromPreviousBreadcrumbPaths(): string {
    let path = ``;

    for (let index = 0; index < this.breadcrumbSegments.length; index++) {
      if (!__.IsNullOrUndefinedOrEmpty(this.breadcrumbSegments[index].path)) {
        path = `${path}${this.breadcrumbSegments[index].path}/`;
      }
    }

    return path;
  }
}
