import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeTileComponent } from './home-tile.component';
import { HomeTileContentModule } from './content/home-tile-content.module';
import { HomeTileHeaderModule } from './header/home-tile-header.module';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HomeTileComponent],
  exports: [
    HomeTileComponent,

    HomeTileContentModule,
    HomeTileHeaderModule
  ]
})
export class HomeTileModule { }
