import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { firstValueFrom, from, merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { authCodeFlowConfig } from './core/authentication/oauth-config';
import { I18nService } from './core/i18n.service';
import { Logger } from './core/logger.service';
import { BaseComponent } from './shared/base/components/base-component';
import { ForbiddenService } from './shared/services/local/forbidden.service';
import { __ } from './shared/functions/object.functions';
import { AuthenticationService } from './core/authentication/authentication.service';
import { IdentityToken } from './shared/models/classes/IdentityToken';
import { UsersService } from './shared/services/users.service';
import { OrganisationsService } from './shared/services/organisations.service';
import { RedirectService } from './shared/services/redirect.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private forbiddenService: ForbiddenService,
    private i18nService: I18nService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private organisationsService: OrganisationsService,
    private dialog: MatDialog,
    private redirectService: RedirectService,
    private authenticationService: AuthenticationService,
    private oAuthService: OAuthService
  ) {
    super();
  }

  async ngOnInit() {
    if (environment.production) {
      Logger.enableProductionMode();
    }

    const shouldDisplayApplication = await this.authenticationService.checkLoginStatusOrLogin();

    if (shouldDisplayApplication === false) {
      return;
    }

    if (!__.IsNullOrUndefinedOrEmpty(this.redirectService.redirectUri)) {
      const redirectUri = this.redirectService.redirectUri;

      if (this.redirectService.redirectUri !== '/') {
        this.redirectService.reset();
      }
      
      this.router.navigateByUrl(redirectUri);
    }

    this.i18nService.init(environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    const onNavigationStart = this.router.events.pipe(filter(event => event instanceof NavigationStart));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationStart)
      .subscribe(event => {
        this.forbiddenService.allow();
      });

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  private decodeToken(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
