import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { DashboardService } from '@app/shared/services/dashboard.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Color, LegendPosition, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'kb-home-tiles-per-day',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxChartsModule
  ],
  templateUrl: './home-tiles-per-day.component.html'
})
export class HomeTilesPerDayComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  data: {
    series: any,
    ordersThisWeek: number,
    ordersLastWeek: number,
    ordersThisMonth: number,
    ordersOverall: number
  } = {
      ordersThisWeek: 0,
      ordersLastWeek: 0,
      ordersThisMonth: 0,
      ordersOverall: 0,
      series: [
        {
          "name":  this.translateService.instant('Home.Tiles.Insights.Series name'),
          "series": [] as any
        }
      ]
    };

  scheme: Color = {
    name: 'primary',
    selectable: true,
    group: ScaleType.Linear,
    domain: DashboardService.DefaultDomain
  };

  LegendPosition = LegendPosition;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private dashboardService: DashboardService,
    private translateService: TranslateService
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    super.addSubscription(
      this.dashboardService
        .getOrdersPerDay()
        .pipe(this.finalizeLoading())
        .subscribe({
          next: (result: any) => {
            this.data = result;
            this.data.series[0].name = this.translateService.instant('Home.Tiles.Insights.Series name');
          }
        })
    );
  }

  /**
   * Format float values to integer
   * @description You have to apply input [trimYAxisTicks]="true" and [yAxisTickFormatting]="axisFormat".
   * @param val Input value
   * @returns Formated value as integer.
   */
  axisFormat(val: any) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
}
