import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { NavigationLinkComponent } from './navigation-link.component';
import { SharedModule } from 'projects/partner/src/app/shared';

@NgModule({
  imports: [
    SharedModule,

    MatIconModule,

    MatTooltipModule,
    RouterModule
  ],
  declarations: [NavigationLinkComponent],
  exports: [NavigationLinkComponent]
})
export class NavigationLinkModule { }
