import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { __ } from '@app/shared/functions/object.functions';
import { Observable } from 'rxjs';
import { I18nService } from '../i18n.service';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private injector: Injector
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const i18nService = this.injector.get(I18nService);

    if (!__.IsNullOrUndefinedOrEmpty(i18nService) && !__.IsNullOrUndefinedOrEmpty(i18nService.language)) {
      request = request.clone({
        setHeaders: { 'Accept-Language' : i18nService.language }
      });  
    }
   
    return next.handle(request);
  }
}
