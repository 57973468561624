<div class="flex flex-row md:flex-col py-1 pt-2 px-4 md:py-0 md:pt-0 md:px-0">

    <!-- HOME -->
    <kb-navigation-link class="w-1/6 md:w-full" link="home" icon="home" [displayName]="'Navigation.Home' | translate">
    </kb-navigation-link>

    <!-- LOGISTICS -->
    <!--
    <kb-navigation-link class="w-1/6 md:w-full" link="partners" icon="browse" [displayName]="'Navigation.My partners' | translate">
    </kb-navigation-link>
    -->

    <!-- ORDERS -->
    <kb-navigation-link class="w-1/6 md:w-full" link="orders" icon="package" [displayName]="'Navigation.Orders' | translate">
    </kb-navigation-link>

    <!-- SETTINGS -->
    <kb-navigation-link class="w-1/6 md:w-full" link="settings" icon="my_projects" [displayName]="'Navigation.Settings' | translate">
    </kb-navigation-link>

    <!-- SUPPORT -->
    <kb-navigation-link class="w-1/6 md:w-full" link="support" icon="favorite" [displayName]="'Navigation.Support' | translate">
    </kb-navigation-link>

    <!-- FAQS -->
    <kb-navigation-link class="w-1/6 md:w-full" link="faqs" icon="history" [displayName]="'Navigation.FAQs' | translate">
    </kb-navigation-link>
</div>
