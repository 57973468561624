import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Logger } from '../logger.service';


const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError(error => this.errorHandler(request, error, next))
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private errorHandler(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes(environment.serverUrl) ||
      request.url.replace(/\/api\/v\d+/, '').includes(environment.serverUrl)
    ) {
      if (error.status === 0) {
        this.inaccessible();
      } else {
        if (error.status === 1001) {
          this.validationError(error);
        } else {
          this.internalServerError(error);
        }
      }
    } else {
      this.toastr.error(
        this.translateService.instant('General.An error that should not happened has happened. Sorry for that. Please contact the administrator')
      );
    }
    // TODO: LOG THE ERRORS
    if (!environment.production) {
      log.error('Request error', error);
    }
    throw error;
  }

  private inaccessible() {
    let message = 'General.The api server is not accessible';
    if (!environment.production) {
      message = message + '. (The webapi server is down)';
    }
    this.toastr.error(this.translateService.instant(message));
  }

  private internalServerError(error: any) {
    if (error.status === 500) {
      // todo: add logging
      this.toastr.error(
        this.translateService.instant('An error that should not happened has happened. Sorry for that. Please contact the administrator')
      );
    }
  }

  private validationError(error: any) {
    if (error.error.code.internalCode === 1001) {
      this.toastr.error(this.translateService.instant('General.A validation error occured. Please correct your input'));
    }
  }
}
