import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsDefaultModule } from '@app/shared/components/buttons/default/buttons-default.module';
import { NavigationLinkModule } from '../../navigation/link/navigation-link.module';
import { SideBarActionsComponent } from './side-bar-actions.component';

@NgModule({
  imports: [
    CommonModule,
    NavigationLinkModule,
  ],
  declarations: [SideBarActionsComponent],
  exports: [SideBarActionsComponent]
})
export class SideBarActionsModule { }
