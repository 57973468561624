import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpCacheService } from '@app/core/http/http-cache.service';
import { __ } from '@app/shared/functions/object.functions';
import { Ticket } from '@app/shared/models/classes/Ticket';
import { ForbiddenService } from '@app/shared/services/local/forbidden.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { AuthenticationService } from './../authentication/authentication.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private forbiddenService: ForbiddenService,
    private translateService: TranslateService,
    private httpCacheService: HttpCacheService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticationService = this.injector.get(AuthenticationService);

    return next.handle(request.clone()).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401 && request.method.toLowerCase() !== 'options') {

          // TODO: QUEUE MULTIPLE REQUEST AND JUST SEND OUT ONE TOKEN REQUEST
          return from(authenticationService.oAuthService.refreshToken()).pipe(
            switchMap((response) => {
              const credentials = authenticationService.credentials;
              credentials.access_token = response.access_token;
              credentials.expires = response.expires_in.toString();
              credentials.refresh_token = response.refresh_token;
              authenticationService.setCredentials(credentials);

              return next.handle(
                request.clone({
                  setHeaders: { Authorization: `Bearer ${response.access_token}` }
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              switch (error.status) {
                case 400:
                case 401:
                case 403:
                  authenticationService.logout().subscribe();
                  
                  return throwError(response);

                default:
                  break;
              }

              return of(null);
            })
          );
        }

        if (response.status === 403) {
          this.forbiddenService.forbid();
        }

        throw response;
      })
    );
  }
}
