import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
  selector: 'kb-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent extends BaseComponent {

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    super();

    this.scrollUp();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  scrollUp(): void {
    const content = this.document.getElementById('content');
    content.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}

