<div style="height: 60%">
    <ngx-charts-line-chart [scheme]="scheme" [xAxis]="true" [yAxis]="true" [trimYAxisTicks]="true" [yAxisTickFormatting]="axisFormat" [results]="data.series" [legend]="false"
        [legendPosition]="LegendPosition.Below">
    </ngx-charts-line-chart>
</div>

<div class="grid grid-cols-4 gap-2 mt-8">
    <div class="">
        <span class="text-gray-500">{{ 'Home.Tiles.Insights.This week' | translate }}</span><br>
        <span class="text-2xl">{{ data.ordersThisWeek }}</span>
    </div>
    <div class="">
        <span class="text-gray-500">{{ 'Home.Tiles.Insights.Last week' | translate }}</span><br>
        <span class="text-2xl">{{ data.ordersLastWeek }}</span>
    </div>
    <div class="">
        <span class="text-gray-500">{{ 'Home.Tiles.Insights.This month' | translate }}</span><br>
        <span class="text-2xl">{{ data.ordersThisMonth }}</span>
    </div>
    <div class="">
        <span class="text-gray-500">{{ 'Home.Tiles.Insights.Overall' | translate }}</span><br>
        <span class="text-2xl">{{ data.ordersOverall }}</span>
    </div>
</div>