import { Component, Input } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
  selector: 'kb-home-tiles-number-card',
  templateUrl: './home-tiles-number-card.component.html'
})
export class HomeTilesNumberCardComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  abs = Math.abs;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  /**
   * The title of the number card
   */
  @Input() title: string;

  /**
   * The primary value for this number card
   */
  @Input() value: number;

  /**
   * The type of the number card. Can either be a number with reference value 
   * or a percentage with a progress bar
   */
  @Input() type: 'number' | 'percentage' = 'number';

  /**
   * The secondary value for this number card, if the type is number.
   * Will be displayed for the reference period.
   */
  @Input() secondaryValue: number;

  /**
   * If the type is number, the reference period needs to be 
   * provided. Should be in a strin format, such as "June 2023", or "2023"
   */
  @Input() referencePeriod: string;

  /**
   * The icon for this number card. This icon needs to be registered as an SVG icon
   * in the CoreModule
   */
  @Input() icon: string;

  /** 
   * The icon background color for this number card 
   */
  @Input() iconBackgroundColor: 'gray' | 'yellow' | 'green' | 'blue';

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
