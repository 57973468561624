import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BaseService } from '../../base/services';

@Injectable()
export class NotFoundService extends BaseService {
    private _notFound: boolean;

    private _notFound$: Subject<boolean> = new Subject<boolean>();

    notFound$: Observable<boolean> = this._notFound$.asObservable();

    notify($event: boolean = true): void {
        if ($event === true || $event === false) {
            this._notFound$.next($event);
            return;
        }

        throw new Error(`[NOT FOUND SERVICE] ${$event} is not a valid value for notify`)
    }
}