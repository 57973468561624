import { Injectable } from '@angular/core';
import { __ } from '@app/shared/functions/object.functions';
import { Chat } from '@app/shared/models/classes/Chat';
import { Message } from '@app/shared/models/classes/Message';
import { Observable, Subject } from 'rxjs';
import { BaseService } from '../../base/services';


@Injectable()
export class MessengerService extends BaseService {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    selectedChat: Chat = null;

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private _selectChat$: Subject<Chat> = new Subject<Chat>();

    private _sendMessage$: Subject<Message> = new Subject<Message>();

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC PRIVATE DEPENDENT INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    // tslint:disable-next-line:member-ordering
    public selectChat$: Observable<Chat> = this._selectChat$.asObservable();

    // tslint:disable-next-line:member-ordering
    public sendMessage$: Observable<Message> = this._sendMessage$.asObservable();

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    selectChat(chat: Chat): void {
        // Deselect chat if already selected
        if (this.selectedChat?.id === chat.id) {
            this.selectedChat = null;
            this._selectChat$.next(null);
            return;
        }
        this.selectedChat = chat;
        this._selectChat$.next(chat);
    }

    sendMessage(message: Message): void {
        this._sendMessage$.next(message);
    }
}
