import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { __ } from '@app/shared/functions/object.functions';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private injector: Injector,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const oAuthService = this.injector.get(OAuthService);

    // TODO: Check if token has expired, if yes, get new one, also queue this
    request = request.clone({
      setHeaders: { Authorization: `Bearer ${oAuthService.getAccessToken()}` }
    });

    return next.handle(request);
  }
}
