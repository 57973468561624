import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Injectable()
export class BasicAuthorizationInterceptor implements HttpInterceptor {
  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(private injector: Injector) {}

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticationService = this.injector.get(AuthenticationService);
    // request = request.clone({
    //   setHeaders: { Authorization: `Basic ${authenticationService.clientId}:${authenticationService.clientSecret}` }
    // });
    return next.handle(request);
  }
}
