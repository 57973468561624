import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/i18n.service';
import { map, Observable } from 'rxjs';

import { EntityService } from './entity.service';
import { Response } from './local/Response';

@Injectable()
export class DashboardService extends EntityService<DashboardResult, DashboardResult, DashboardResult> {

    public static DefaultDomain = ['#213833', '#42BFBA', '#B5FF00', '#F7AB46', '#4371B7', '#15A860'];

    constructor(protected httpClient: HttpClient, protected i18nService: I18nService) {
        super('dashboard', 'dashboard', httpClient, i18nService);
    }

    getDashboardOverview(
        filterParameters: string = '',
        withAccessToken: boolean = true,
        withAllLanguages: boolean = false
    ): Observable<DashboardOverviewMetricsDto> {
        let httpClient = this.httpClient;

        if (withAccessToken === false) {
            httpClient = httpClient.disableAccessToken();
        }

        let headers = new HttpHeaders();

        headers = headers.set('Accept-Language', this.i18nService.language);

        if (withAllLanguages === true) {
            headers = headers.set('Accept-Language', this.i18nService.supportedLanguages.map(q => q.locale).join(','));
        }

        return httpClient.disableAcceptedLanguage().get<Response<DashboardOverviewMetricsDto>>(
            `${this.endpoint}/overview-metrics?${filterParameters}`,
            { headers }
        ).pipe(
            map((response: Response<DashboardOverviewMetricsDto>) => {
                return response.data;
            })
        );
    }

    getOrdersPerDay(): Observable<DashboardOverviewMetricsDto> {
        let httpClient = this.httpClient;

        return httpClient.get<Response<DashboardOverviewMetricsDto>>(
            `${this.endpoint}/orders-per-day`
        ).pipe(
            map((response: Response<DashboardOverviewMetricsDto>) => {
                return response.data;
            })
        );
    }

    getOrdersPerDeliveryMethod(): Observable<DashboardOverviewMetricsDto> {
        let httpClient = this.httpClient;

        return httpClient.get<Response<DashboardOverviewMetricsDto>>(
            `${this.endpoint}/orders-per-delivery-method`
        ).pipe(
            map((response: Response<DashboardOverviewMetricsDto>) => {
                return response.data;
            })
        );
    }

    getOrdersPerCity(): Observable<DashboardOverviewMetricsDto> {
        let httpClient = this.httpClient;

        return httpClient.get<Response<DashboardOverviewMetricsDto>>(
            `${this.endpoint}/orders-per-city`
        ).pipe(
            map((response: Response<DashboardOverviewMetricsDto>) => {
                return response.data;
            })
        );
    }
}

export class DashboardResult { }

export class DashboardOverviewMetricsDto {
    openOrdersCount: number;
    ordersInCurrentMonthCount: number;
    ordersInPreviousMonthPercentage: number;
    ordersInCurrentYearCount: number;
    ordersInPreviousYearPercentage: number;
    inProgressCount: number;
}
