<kb-home-tile class="h-full">

  <!-- HEADER -->
  <kb-home-tile-header appearance="narrow">{{ title }}</kb-home-tile-header>

  <!-- CONTENT -->
  <kb-home-tile-content>

    <div class="flex">
      <h2 class="flex-auto text-7xl">
        {{ value ?? 0 }}
      </h2>
      <!-- 
      <div *ngIf="!IsNullOrUndefinedOrEmpty(icon)" class="flex-initial ml-4 -mt-6">
        icon
      </div> -->

    </div>

    <div *ngIf="!IsNullOrUndefinedOrEmpty(referencePeriod)" class="text-xs flex mt-2">
      <div class="flex" [class.text-red-900]="secondaryValue < 0" [class.text-green-900]="secondaryValue > 0">
        <div>
          <ng-container *ngIf="secondaryValue < 0">
            ▼
          </ng-container>
          <ng-container *ngIf="secondaryValue > 0">
            ▲
          </ng-container>
          <ng-container *ngIf="secondaryValue === 0">
            —
          </ng-container>
        </div>

        <div class="ml-4">
          {{ abs(secondaryValue) }}%
        </div>

      </div>

      <div class="text-gray-500 ml-4">
        {{ 'Home.Tiles.in comparison to' | translate }} {{ referencePeriod }}
      </div>

    </div>

  </kb-home-tile-content>

</kb-home-tile>